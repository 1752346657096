@use "sass:meta";
@tailwind base;
@tailwind components;
@tailwind utilities;

html[style*="color-scheme: light;"] {
  @include meta.load-css("highlight.js/styles/atom-one-light.css");
  .hljs {
    background: beige;
  }
}
html[style*="color-scheme: dark;"] {
  @include meta.load-css("highlight.js/styles/atom-one-dark.css");
}

pre {
  padding: 0 !important;
  border-radius: 0 !important;
}

/* User styles */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition:
    background-color 600000s 0s,
    color 600000s 0s;
}

.katex-display {
  overflow: auto hidden;
}

.content-header-link {
  opacity: 0;
  margin-left: -24px;
  padding-right: 4px;
}

.content-header:hover .content-header-link,
.content-header-link:hover {
  opacity: 1;
}

.linkicon {
  display: inline-block;
  vertical-align: middle;
}

#spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    opacity:0.5;
}

.htmx-indicator{
    display:none !important;
}
.htmx-request .htmx-indicator{
    display:inline !important;
}
.htmx-request.htmx-indicator{
    display:inline !important;
}

/* Alpine.js styles */

[x-cloak] {
  display: none !important;
}

.duration-300 {
    transition-duration: 300ms;
}

.ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.scale-90 {
    transform: scale(.9);
}

.scale-100 {
    transform: scale(1);
}


@keyframes fade-in {
  from { opacity: 0; }
}

@keyframes fade-out {
  to { opacity: 0; }
}

@keyframes slide-from-right {
  from { transform: translateX(90px); }
}

@keyframes slide-to-left {
  to { transform: translateX(-90px); }
}

::view-transition-old(slide-it) {
  animation: 180ms cubic-bezier(0.4, 0, 1, 1) both fade-out,
  500ms cubic-bezier(0.4, 0, 0.2, 1) both slide-to-left;
}
::view-transition-new(slide-it) {
  animation: 420ms cubic-bezier(0, 0, 0.2, 1) 90ms both fade-in,
  500ms cubic-bezier(0.4, 0, 0.2, 1) both slide-from-right;
}
.slide-transition {
  view-transition-name: slide-it;
}